/**=====================
   55. theme layout CSS Start
==========================**/
//Sidebar color scss
$color1 : #405365;
$color2 : #1C1D1E;
$color3 : #0c0c3c;
$color4 : #0747a6;
$color5 : #4d3096;
@each $bg-name, $bg-color in (color1, $color1),
				(color2, $color2) ,
				(color3, $color3),
				(color4, $color4),
				(color5, $color5),
				(light, $light-gray),
				(dark, #2f3c4e){
	.bg-#{$bg-name}{
		background-color:$bg-color ;
	}
}
$i:1;
@while $i<=6 {
	.bg-pattern#{$i}{
		background-image: url(../images/sidebar-pattern/#{$i}.png);
		background-color: #2f3c4e;
	}
	.bg-img#{$i}{
		background-image: url(../images/sidebar-image/#{$i}.jpg);
	}
	$i:$i+1;
}
//Sidebar settings
.page-wrapper.horizontal-wrapper {
	.page-body-wrapper {
		.page-sidebar{
			&[sidebar-layout="border-sidebar"] {
				.sidebar-header {
					border-bottom: 1px solid rgba(124, 124, 124, 0.15);
					transition: all 0.3s ease;
				}
			}
			&[sidebar-layout="iconcolor-sidebar"]{
				.sidebar-menu{
					li{
						$colors: $success-color, $secondary-color, $primary-color, $danger-color, $light-font, $warning-color, $light-gray;
						$repeat: 100 ;
						@for $i from 1 through $repeat {
							&:nth-child(#{length($colors)}n+#{$i}) {
								svg{
									color: lighten(nth($colors, random(length($colors))), 15%);
								}
								a{
									&:hover{
										> span{
											color: lighten(nth($colors, random(length($colors))), 15%);
										}
									}
								}
							}
						}
					}
				}
				&.light-sidebar{
					.sidebar-menu{
						li{
							$colors: $success-color, $secondary-color, $primary-color, $danger-color, $light-font, $warning-color, $dark-color;
							$repeat: 100 ;
							@for $i from 1 through $repeat {
								&:nth-child(#{length($colors)}n+#{$i}) {
									svg{
										color: darken(nth($colors, random(length($colors))), 15%);
									}
									a{
										&:hover{
											span{
												color: darken(nth($colors, random(length($colors))), 15%);
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.sidebar-header {
				transition: all 0.3s ease;
			}
		}
		&.sidebar-icon {
			.page-sidebar {
				&.light-sidebar {
					.sidebar-menu {
						li {
							&:hover {
								.single-header {
									background-color: $light-gray !important;
								}
								.sidebar-submenu {
									background-color: $light-gray !important;
								}
							}
							a:hover + ul {
								background-color: $light-gray !important;
							}
							ul {
								li{
									&:hover {
										.sidebar-submenu {
											background-color: $light-gray !important;
										}
									}
								}
							}
						}
					}
				}
				&.color1-sidebar {
					.sidebar-menu {
						li {
							&:hover {
								.single-header {
									background-color: $color1 !important;
								}
								.sidebar-submenu {
									background-color: $color1 !important;
								}
							}
							a:hover + ul {
								background-color: $color1 !important;
							}
							ul {
								li{
									&:hover {
										.sidebar-submenu {
											background-color: $color1 !important;
										}
									}
								}
							}
						}
					}
				}
				&.color2-sidebar {
					.sidebar-menu {
						li {
							&:hover {
								.single-header {
									background-color: $color2 !important;
								}
								.sidebar-submenu {
									background-color: $color2 !important;
								}
							}
							a:hover + ul {
								background-color: $color2 !important;
							}
							ul {
								li{
									&:hover {
										.sidebar-submenu {
											background-color: $color2 !important;
										}
									}
								}
							}
						}
					}
				}
				&.color3-sidebar {
					.sidebar-menu {
						li {
							&:hover {
								.single-header {
									background-color: $color3 !important;
								}
								.sidebar-submenu {
									background-color: $color3 !important;
								}
							}
							a:hover + ul {
								background-color: $color3 !important;
							}
							ul {
								li{
									&:hover {
										.sidebar-submenu {
											background-color: $color3 !important;
										}
									}
								}
							}
						}
					}
				}
				&.color4-sidebar {
					.sidebar-menu {
						li {
							&:hover {
								.single-header {
									background-color: $color4 !important;
								}
								.sidebar-submenu {
									background-color: $color4 !important;
								}
							}
							a:hover + ul {
								background-color: $color4 !important;
							}
							ul {
								li{
									&:hover {
										.sidebar-submenu {
											background-color: $color4 !important;
										}
									}
								}
							}
						}
					}
				}
				&.color5-sidebar {
					.sidebar-menu {
						li {
							&:hover {
								.single-header {
									background-color: $color5 !important;
								}
								.sidebar-submenu {
									background-color: $color5 !important;
								}
							}
							a:hover + ul {
								background-color: $color5 !important;
							}
							ul {
								li{
									&:hover {
										.sidebar-submenu {
											background-color: $color5 !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

//Dark layout
body {
	&.light-only{
		.page-wrapper.horizontal-wrapper{
			.page-body-wrapper{
				.page-body {
					background-color: $light;
					ul.the-icons {
						li {
							color: $black;
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px $dark-card-background;
								color: $white;
							}
							em{
								display: none;
							}
						}
					}
				}
			}
		}
	}
	&.dark-only {
		color: $dark-all-font-color;
		background-color: $dark-body-background;
		// display:none;
		.blog-box {
			h6 {		
				color: $white;
			}
			.blog-details-second {
				.detail-footer {			
					border-top: 1px solid $dark-body-background;
					ul.sociyal-list li:nth-child(n+2) {					
						border-left: 1px solid $dark-body-background;
					}
				}
			}
		}
		a.cke_button{
			filter: brightness(0) invert(1);
		}
		.cke_combo_text{
			color: $white;
		}
		.job-search .job-description {		
			border-top: 1px solid $dark-body-background;			
		}
		.job-sidebar{
			.job-left-aside{
				background-color: $dark-card-background;
				border-color:$dark-card-border
			}
		}
		.job-search .job-description .theme-form{
			border-bottom: 1px solid $dark-body-background;	
		}
		.categories ul li:hover {
			background-color: $dark-body-background;
		}
		.blog-list .blog-details h6 {		
			color: $white;
		}
		.default-according{
			.card-header{
				background-color: $dark-card-background !important;
			}
		}
		.login-form {
			background-color: $dark-body-background;		
			.login-social-title h5 {
				background-color: $dark-body-background;
			}
		}
		.calendar-basic{
			.tui-full-calendar-month-dayname{
				span {				
					color: $white !important;
				}
			}
		}
		.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time .tui-full-calendar-weekday-schedule-title,
		.tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-date{
			color: rgba($white, 0.8) !important;
		}
		.tui-full-calendar-dropdown-menu-item .tui-full-calendar-content {			
			color: $black;
		}
		.tui-full-calendar-weekday-border{
			border-top: 1px solid $dark-small-font-color !important;
		}
		.tui-full-calendar-month-dayname {		
			border-top-color: $dark-small-font-color !important;
		}
		.tui-full-calendar-layout{
			background-color: $dark-body-background !important;
		}	
		.calendar-basic #menu-navi {			
			border-bottom: 1px solid $dark-body-background;
		}	
		.social-app-profile .card .socialprofile span .badge {			
			background-color: $dark-body-background;
			color: $white;			
		}
		.social-list .media .media-body span {		
			color: inherit;
		}
		.edit-profile .profile-title {
			border-bottom: 1px solid $dark-body-background;			
		}
		.search-list{
			.nav-item{
				.nav-link {			
					color: $white;
					opacity: 1;
					&.active{
						text-shadow: 0px 0px $white;
					}
				}
			}
		}
		
		.datepicker--pointer {			
			background: $dark-card-background;
			border-top: 1px solid $dark-body-background;
			border-right: 1px solid $dark-body-background;			
		}
		.form-label {
			color: inherit;
		}
		// theme-color
		.txt-success{
			color: $dark-all-font-color !important;
		}
		.project-box .details div{
			opacity: 1;
		}
		.project-box {
			p{
				color: $dark-all-font-color;
			}
		}
		.checkout label {
			color: inherit;
		}
		.prooduct-details-box .product-name h6{
			color: $dark-all-font-color;
		}
		.product-box .product-img .product-hover ul li a {
			color: $white;
		}
		.border-tab.nav-left .nav-link {
			color: $white;
		}
		.order-history table .qty-box{
			background-color: $dark-card-background;
		}
		.product-box {		
			background-color: $dark-card-background;
			.product-details h4 {
				color: $light-semi-gray;
			}
		}		
		.flot-chart-placeholder .legend table{
			color: $white !important;
		}
		.compact-wrapper tbody{
			background-color: $dark-body-background;
		}
		// Index page  start
		.dashboard-default-sec,.dashboard-2-main {
			.card {
				.card-header {
					p{
						color: $dark-all-font-color;
					}
					ul.week-date {
						li{
							color: $dark-all-font-color;
							&:nth-child(n+2){
							border-color: $dark-card-border;
							}
						}
					}		
				}
				&.income-card {
					.card-body {
						.knob-block {
							input {
								color: $dark-all-font-color  !important;							
							}
						}
					}
				}			
			}
			
			.latest-update-sec{
				table{
					tbody{
						tr{
							td{
								img{
									filter: brightness(0) invert(1);
								}
							}
						}
					}
				}
				
			}
						 
			span,p,h5,h4,h5,h6,th,h3,a{
				color: $dark-all-font-color;
			}
			.recent-order-sec{
				table{
					tr{
						td{
							p{
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			
			
		}
		.setting-list{
			background-color:$dark-card-background;
		}
		.latest-update-sec {
			.media {
				img,svg{
				fill: $dark-small-font-color;
				}
			}
		}
		.dashboard-default-sec {
			.card {
				.card-header {
					ul.week-date {
						li:nth-child(n+2){
							border-color: $dark-card-border;
						}
					}
				}
			}
		}
		.top-dealer-sec	 {
			.card {
				.top-dealerbox{
					&:hover{
						background-color: $dark-body-background;
					}				
				}
			}
		}


		// dashboard-2 dark css start
		.activity-sec{
			.activity-timeline{
				.media{
					.activity-line{
						background-color: #374558;
					}
					.media-body {
						p{
							color:$dark-all-font-color;
						}
					}
				}
				&.update-line {
					.media {
						.media-body {
							.tomorrow-sec {
								p{
								color:$dark-all-font-color;		
								background-color: $dark-card-background;
								}
								&:before{
									background-color: $dark-card-border;								
								}
							}						
						}
					}
				}
			}
			
		}
		.activity-dot-primary{
			background-color: $dark-card-background;
		}

		//user profile
		.user-profile {
			.profile-header {
				.userpro-box{
					background-color: $dark-card-background;
					h4{
						color:$dark-all-font-color;
					}
					.icon-wrapper{
						background-color: $dark-body-background;
					}
				}
			}
			.follow {
				.follow-num{
					color:$dark-all-font-color;
				}
				ul.follow-list{
					border-color:$dark-card-border;
					li{
						&:nth-child(n+2){
							border-color: $dark-card-border
						}
					}
				}
			}		
			.post-about {
				ul {
					li {
						h5{
							color: $dark-all-font-color;
						}
					}
				}
			}
			.profile-post{
				.post-header{
					.media{
						h5{
						   color: $dark-all-font-color;
						}
					}
				}
				.post-body {
					.post-react {
						h6{
							color: $dark-all-font-color;
						}
						p{
							color: $dark-small-font-color
						}
					}
				}
			}
			
		}
		.social-tab {
			.input-group {
				.form-control{
					border-color:$dark-card-border
				}
			}
		}
			
		
		// widget //
		.cal-date-widget {
			.datepicker{
				background-color:$dark-card-border;
				border:none;
				.datepicker--cell-day{
					color: $white;
				}
			}
		}
		.datepicker--day-name{
			color: $primary-color;
		}
		.product-social {
			li {
				a{
					border-color: $dark-card-border;
				}
			}
		}
		.page-wrapper,.compact-wrapper {
			.page-body-wrapper {
				.page-body {
					.widget-joins{
						&:before,&:after{
							background-color: $dark-card-border !important;
						}
					}
				}
			}		
			&.compact-wrapper {
				.page-body-wrapper{				
						header{
							&.main-nav {
								.sidebar-main-title {
									> div{
										border-color: $dark-card-border;
										p{
											color:$dark-all-font-color;
										}
									}
								}
								.main-navbar {
									.nav-menu {
										> li {
											.nav-link {
												svg{
													color: $dark-all-font-color !important;
												}
											}
										}
									}
								}
							}
						}				
				}
			} 
			.product-page-main{
				thead,tbody,  tfoot,tr, td, th{
					border-color: transparent;
					background-color: transparent;
				}	
				p{
					color: $dark-small-font-color;
				}
			}
			thead,tbody,  tfoot,tr, td, th{
				border-color: $dark-card-border;
			}			  			 
		}	
		.testimonial{
			i{
				color:$primary-color !important; 
			}
		}
		.blockquote{
			border-color: $dark-card-border;
		}
		.datepicker,.daterangepicker{
			background-color:$dark-card-background;
			border-color: $dark-card-border;			
		}
		.daterangepicker{
			&:after{
				border-bottom-color:$dark-card-border;
			}
			.input-mini{
				&.active{
					border:1px solid $dark-border-color;
				}
			}
		}
		.daterangepicker {
			.calendar-table{
				background-color:$dark-card-background;
				border:none;
			}
		}
		.daterangepicker.dropdown-menu{
			background-color:$dark-card-background;
		}
		.datepicker--nav{
			border-color:$dark-card-border;
		}
		.datepicker--cell-day{
			color:$dark-all-font-color
		}
		.datepicker--cell-day,.datepicker--cell-year{
			&.-other-month-,.-other-decade-{
				color: $dark-small-font-color;
			}
		}
		.date-range-picker {
			.daterange-card{
				border-color:$dark-card-border;
			}
		}
		.daterangepicker {
			td{
				&.off,&.in-range,&.start-date,&.end-date{
					background-color:$dark-body-background;
					color: $dark-all-font-color;
				}
			}
			.input-mini{
				border-color:$dark-card-border !important;
				background-color: $dark-card-background;
			}
			&.ltr {
				.calendar-time {
					select{
						background-color:$dark-card-background;
						border-color: $dark-card-border;
					}
				}
			}
		} 		 	
		.ranges {
			li{
				background-color: $dark-card-background;
				border-color: $dark-card-border;
			}
		}

		.f1 {
			.f1-steps {
				.f1-progress{
					background-color: $dark-border-color;
				}
				.f1-step {
					.f1-step-icon{
						background-color:$dark-body-background;
					}
					&.active {
						.f1-step-icon{
							background-color:$primary-color;
						}
					}
				}
			}
		}

		//card //
		.b-b-info{
			border-color:$dark-card-border !important;
		}

	
		// sidebar 
		.main-navbar {
			ul.nav-pills{
				border-color:$dark-card-border;
				.nav-item{
					&:last-child{
						border-color:$dark-card-border;
					}
					.nav-link{
						color:$dark-all-font-color;					
					}
				}
			}
		}
		.messanger-chat{
			.chat{
				.message-main{
					p{
						color: $dark-card-border;
					}
				}
			}
		}	

		// project list
		.project-list {
			.border-tab{
				border:none !important;			
				&.nav-tabs {
					.nav-item {
						.nav-link{
							border:none;
						}
					}
				}
			}
		}
		.project-box{
			background-color: $dark-body-background;
			border-color: $dark-card-border
		}
		.file-content {
			.form-control-plaintext{
				color:$dark-all-font-color;
				&:focus{
					color:$dark-all-font-color;
				}
			}
			.form-inline{
				border-color: $dark-card-border;
			}
			.files {
				.file-box{
					border-color: $dark-card-border;
					.file-top{
						background-color: $dark-body-background;
						border-color:$dark-border-color;
					}
				}
			}
			.folder {
				.folder-box{
					border-color:$dark-card-border;
				}
			}	
		}
		.file-manager{
			> h6{
				color: $dark-all-font-color;
			}
		}

		.file-sidebar {
			.pricing-plan{
				border-color:$dark-card-border;
				h6{
					color: $dark-all-font-color;
				}
				p{
					color: $dark-small-font-color;
				}
			}
			
		}

		// kanban board//
		.kanban-board{
			background-color: $dark-body-background ;
		}
		.kanban-item {
			.kanban-box{
				border-color:$dark-card-border;
				background-color: $dark-body-background;
			}
		}

		.kanban-container {
			footer{
				border:none !important;
			}
		}
		



		//dashboard-04 end
		.apexcharts-gridline {
			stroke: $dark-card-border;
		}
		.apexcharts-tooltip-title, .apexcharts-tooltip-series-group, .apexcharts-tooltip.light, .apexcharts-menu-item {
			background-color: $dark-body-background !important;
		}
		.activity-sec .activity-timeline.update-line .media .media-body h5{
			color: rgba($white, 0.8);
		}
		.page-main-header{
			.main-header-right{
				 box-shadow: 0 0 18px 3px $dark-card-background;
				.nav-right{
					&.right-menu{
						ul{
							li{
								&:nth-child(2){
									border-left: 1px solid $dark-body-background;
									border-right: 1px solid $dark-body-background;
								}
							}
						}
					}
					.bookmark-dropdown{
						ul{
							li{
								&:hover{
									background-color: $dark-body-background;
								}
							}
						}
					}
				}
				.main-header-left{
					border-right: 1px solid $dark-card-border;
					border-color: $dark-card-border;
					.logo-wrapper{
						display:none;
					}
					.dark-logo-wrapper{
						display: block;
					}
				}		
				
				.left-menu-header {
					ul {
						li {
							.search-form {
								input{
									&::placeholder{
										color:$dark-all-font-color;
									}
								}
							}
						}
					}
				}	
			}

			
		}

		
		 
		.page-wrapper {
			.page-body-wrapper{
				background-color: $dark-card-background
			}							
			&.horizontal-wrapper {
				.page-body-wrapper{
					&.horizontal-menu {
						header{
							&.main-nav {
								background-color: $dark-card-background;
								border-color:$dark-card-border ;							
								.main-navbar {								
									.nav-menu{
										>li{
											+li{
												border-color: $dark-card-border;
											}
											>a{
												color: $dark-all-font-color;
											}
										}
									}
									.left-arrow, .right-arrow{
										background-color: $dark-card-background;
										border-color: $dark-card-border
									}
								}
							}
						}
					}
				}
			}
		}
		.page-wrapper.horizontal-wrapper {
			.page-body-wrapper {				
				.sidebar {
    				box-shadow: 0 0 11px $dark-card-background;
				}
			}
				$alert-name:primary,
				secondary,
				success,
				danger,
				warning,
				info,
				light,
				dark;

				$alert-color :$primary-color,
				$secondary-color,
				$success-color,
				$danger-color,
				$warning-color,
				$light-font,
				$light-gray,
				$dark-color;
		 }
				@each $var in $alert-name {
				  $i: index($alert-name, $var);
				  .alert-#{$var}{
				    background-color: rgba(nth($alert-color, $i), 0.8) !important;
				    border-color: rgba(nth($alert-color, $i), 0.9) !important;
				    color: $white;
				    .progress {
				      height: 5px;
				      background-color: darken(nth($alert-color, $i), 1%);
				      border-radius: 0;
				    }
				    .progress-bar{
				      background-color: lighten(nth($alert-color, $i), 50%);
				    }
				  }
					.alert-#{$var}.inverse{
				    background-color: $transparent-color !important;
				  }
				}
				.default-according {
					.card {
						.card-body {
							border: 1px solid $dark-card-border;
						}
					}
				}
		.options {
			> div {
				border: 1px solid $dark-body-background;
			}
		}
		.pricing-simple {
			box-shadow: 1px 1px 2px 1px $dark-body-background !important;
		}
		.pricing-block {
    	box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}
		.search-page {
			ul {
				&.search-info {
					 li {
						 + li {
							 border-left: 1px solid $dark-card-border;
						 }
					 }
				}
			}
		}
		.search-form{
			input{
				border: 1px solid $dark-card-border;
				background-color: $dark-body-background
			}
		}
		.form-control{
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}
		.input-group-text{
			color:$dark-all-font-color;
		}
		.input-group-text{
			border: 1px solid $dark-card-border;
			background-color:$dark-card-background;
		}
		.status-circle{
			border: 2px solid $dark-card-border;
		}
		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid $dark-card-border;
				}
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid $dark-card-border;
								}
							}
						}
					}
				}
			}
		}
		.calendar-wrap {
			.fc-unthemed {
				td, th {
					border-color: $dark-card-border;
				}
			}
		}
		.mega-inline, .mega-horizontal {
			.card {
				border: 1px solid $dark-card-border;
			}
		}
		.setting-dot{
			.setting-bg{
				background-color: $secondary-color;
			}
		}
		.bookmark.pull-right {
			border: none;
		}
		.card{
			.card-header{
				h5{
					background: unset;
					-webkit-text-fill-color: $white;
				}
			}
		}
		.timeline-circle{
			.media{
				.timeline-background{
					.timeline-dot-success{
						background: $dark-body-background;
					}
					.timeline-dot-primary{
						background: $dark-body-background;
					}
					.timeline-dot-warning{
						background: $dark-body-background;
					}
					.timeline-dot-secondary{
						background: $dark-body-background;
					}
					.timeline-dot-info{
						background: $dark-body-background;
					}
				}
				p,span{
					color:$dark-all-font-color;
				}
			}
		}
		.apex-chart-container{
			.apexcharts-legend{
				.apexcharts-legend-series{
					span{
						color: $dark-all-font-color !important;
					}
				}
			}
		}
		.apexcharts-canvas{
			svg{
				.apexcharts-title-text{
					fill: $white;
				}
				.apexcharts-subtitle-text{
					fill: $white;
				}
				.apexcharts-yaxis{
					.apexcharts-yaxis-texts-g{
						.apexcharts-yaxis-label{
							fill: $white;
						}
					}

				}
				.apexcharts-xaxis{
					.apexcharts-xaxis-texts-g{
						.apexcharts-xaxis-label{
							fill: $white;
						}
					}

				}
				.apexcharts-legend-series{
					span{
						color: $dark-all-font-color !important;
					}
				}
				.apexcharts-datalabels-group{
					.apexcharts-datalabel-label{
						fill: $white;
					}
					.apexcharts-datalabel-value{
						fill: $white;
					}
				}
			}
		}
		.Typeahead-menu {
			background-color: $dark-body-background;
		}
		.ecommerce-widget {
			border: 1px solid $dark-card-border  !important; 
		}
		.contact-form {
			.theme-form{
				border-color: $dark-card-border !important ;
			}
		}
		.bookmark {
			ul {
				li {
					border: 1px solid $dark-card-border;
					background-color: $dark-card-background;
					.search-form {
						.form-control-search {
							input {
								background-color: $dark-body-background;
								border: 1px solid $dark-card-border;
								color: $dark-all-font-color;
							}
							&:before {
								background: $dark-card-border;
							}
						}
					}
				}
			}
		}
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: $dark-card-border !important;
					}
				}
			}
		}
		.checkout{
			.checkout-details {
				background-color: $dark-body-background;
				border: 1px solid $dark-card-border;
				padding: 40px;
				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid $dark-card-background;
						}
					}
				}
			}
		}
		
		.order-box {
			.title-box{
				color: #bfc2c6;
				border-bottom: 1px solid $dark-card-border;
			}
			.qty {
				li{
					color: #bfc2c6;
					span{
						color: #bfc2c6;
					}
				}
				border-bottom: 1px solid $dark-card-border;
			}
			
			.sub-total {
				li{
					color: #bfc2c6;
				}
				.shipping-class{
					border-color: $dark-card-border;
				}
			}
			.total {
				li{
					color: #bfc2c6;
				}
			}
		}
		.shopping-checkout-option{
			.checkbox_animated{
				&:after{
					border: 2px solid $dark-card-background;
				}
			}
		}
		.animate-chk{
			.radio_animated{
				&:after{
					border: 2px solid $dark-card-border;
				}
			}
		}
		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}
		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: $dark-small-font-color;
							}
							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.radio_animated, .checkbox_animated{
			&:after {
				background: $dark-body-background;
				border: 2px solid $dark-body-background;
			}
		}
		.slider-product {
			border-top: 1px solid $dark-card-border;
			border-bottom: 1px solid $dark-card-border;
		}
		.square-product-setting {
			.icon-grid {
				background-color: $dark-card-background;
				svg {
					color: $dark-all-font-color;
				}
			}
		}
		.active-order-table, .market-table {
			table{
				thead{
					tr{
						th {
							border-top: none !important;
						}
					}
				}
				tbody{
					tr{
						td{
							border-bottom: 1px solid $dark-card-border;
						}
						&:last-child{
							td{
								border-bottom: none;
							}
						}
					}
				}
			}
		}
		.pricing-card-design-2{
			.pricing-block{
				.pricing-inner{
					ul {
						li {
							border-bottom: 1px solid $dark-card-border;
						}
					}
				}
			}
		}
		.flot-chart-container{
			.legend{
				.table{
					tbody{
						background-color: $dark-card-background;
						color: $white;
						.legendLabel{
							padding-left: 5px;
						}
					}
				}
			}
		}
		.google-visualization-tooltip{
			text{
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}
		.nav-dark ,.nav-pills.nav-dark{
			.nav-link{
				color: $white;
			}
		}
		.loader-wrapper {
			background-color: $dark-body-background ;
			.loader {
				background-color: $dark-body-background !important;
			}
		}
		.page-wrapper{
			&.compact-wrapper{
				.page-body-wrapper{				
					header{
						&.main-nav{
							background-color:$dark-card-background;
							border-right:1px solid $dark-card-border;
							border-color:$dark-card-border;
							.nav-menu{								
								> li{
									> a{
										color: $dark-all-font-color;
									}
								}
								.dropdown {
									ul.nav-submenu {
										li {
											&:hover{
												a{
													color: $white !important;
												}
											}
										a {
											color: rgba($white, 0.8) !important;
												&.active {
													color: $white !important;
													text-shadow: 0px 0px $white;
												}
											}
										}
									  }
									}
								}
							.sidebar-user{
								border-color:$dark-card-border;
								ul{
									li{
										+li{
											&:before{
												background-color: $dark-card-border;
											}
										}
									}
								}
								p{
									color: $dark-all-font-color;
								}
							}
							.main-navbar{
								.nav-menu{
									>li{
										.mega-menu-container {
											.mega-box{
												.link-section{
													.submenu-title{
														h5{
															color:$dark-all-font-color;
														}
													}
													.submenu-content{
														&.opensubmegamenu{
															ul{
																li{
																	a{
																		color: $dark-all-font-color;
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.according-menu{
						i{
							color: $dark-all-font-color;
						}
					}				
				}
				.page-main-header{
					border-bottom: 1px solid $dark-card-border;
				}

			}

			&.compact-sidebar{
				&.compact-wrapper {
					.page-body-wrapper {
						header{
							&.main-nav {
								.main-navbar {
									.nav-menu {
										.dropdown {
											.menu-title{
												&.active {
													~ .menu-content{
														background-color:$dark-card-background;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

		}
		.btn-primary-light{
			color: $dark-all-font-color;
		}	

		// popover //
		.popover{
			border-color:$dark-card-border;
		}
		.popover-header{
			background-color:$dark-card-background;
			border-color:$dark-card-border;
		}
		.popover-body{
			background-color:$dark-card-background;
			color:$dark-all-font-color;
		}
		.bs-popover-end,.bs-popover-auto[data-popper-placement^="right"] {
			> .popover-arrow{
				&:after{
					border-right-color:$dark-body-background;	
				}
			}
		}
		.bs-popover-bottom,.bs-popover-auto[data-popper-placement^="bottom"] {
			> .popover-arrow{
				&:after{
					border-right-color:$dark-body-background;
				}
			}
		}	
		.bs-popover-top,.bs-popover-auto[data-popper-placement^="top"] {
			> .popover-arrow{
				&:after{
					border-top-color:$dark-body-background;
				}
			}
		}	
		
		.bs-popover-start,.bs-popover-auto[data-popper-placement^="left"] {
			> .popover-arrow{
				&:after{
					border-left-color:$dark-body-background;
				}
			}
		}	

		// according 
		.default-according {
			.card {
				.card-header{
					border-color:$dark-card-border;
				}
			}
		}

		.border-tab{
			&.nav-tabs {
				.nav-item {
					.nav-link{
						border-bottom:2px solid $dark-card-border;
						&.active,&.show,&:focus{
						border-color: $primary-color;
						}
					}
				}
			}		
			&.nav-left{
				&.nav-success {
					.nav-link{
						&.active{
							border-left: $light-font;
						}
					}
					 .show {
						> .nav-link{
							border-left: $light-font;
						}
					 }
				}
			}

			&.nav-success {
				.nav-item {
					.nav-link{
						&.active,&.show,&:focus,&:hover{
							color: $dark-all-font-color !important;
						}
					}
				}
			}		
		}	

	
		
		// form //
		.form-control, .form-select{
			background-color: $dark-card-border;
			border-color: $dark-card-border;
			color:$dark-all-font-color;
		}

		.was-validated,.form-check-input {
			.form-check-input{
				&:invalid{
					background-color:$dark-card-background;
				}
			} 
			.is-invalid{
				background-color:$dark-card-background;
			}
		}
		

		// step //
		.u-pearl{
			&.error {
				.u-pearl-icon,.u-pearl-number{
					background-color:$dark-card-background;
				}
			}
		}
		// img croper //
		.docs-data{
			.input-group-text{
				color:$light-gray;
			}
		}
		.options {
			> div{
				border-color:$dark-card-border;
			}
		}

		// table //
		.progress{
			background-color:$dark-border-color;
		}

		// datatable //	
		.dataTables_wrapper {
			table.dataTable {
				tbody {
					td,th{
						background-color:$dark-card-background;
						border-color: $dark-card-border;
					}
				}
			}

			&.no-footer {
				.dataTables_scrollBody{
					border-color:$dark-card-border;
				}
			}
		}	
		.cd-timeline-content{
			background-color:$dark-card-background;
		}

		// calender //
		.calendar-wrap {
			.fc-toolbar {
				.fc-left,.fc-right {
					.fc-prev-button,.fc-next-button {
						.fc-icon-left-single-arrow,.fc-icon-right-single-arrow{
							&:after{
								color:$dark-all-font-color;
							}
						}
					}
				}
			}
		}	


		// pagintaion //
		.pagination-primary {
			.page-item {
				.page-link{
					background-color: $dark-card-background;
					border-color: $dark-border-color;
					color: $dark-all-font-color !important;
				}
			}
		}

		// support ticket //
		.support-ticket {
			.pro-gress {
				ul {
					li{
						color: $dark-small-font-color;
					}
				}
			}
		}

		//bookmark//
		.bookmark-tabcontent {
			.tab-content {
				.card {
					.card-header{
						border-color:$dark-card-border;
					}
				}
			}
			
		}
		.bookmark-wrap {
			.left-bookmark {
				ul {
					li {
						a > {
							.title{
								color: $dark-all-font-color;
							}
						}
						button{
							&.badge-light{
								background-color: rgba($primary-color,0.2);
								color: $primary-color;
							}
						}
					}
				}
			}
		}

		// chat
		.chat-box {
			.chat-right-aside {
				.chat {
					.chat-msg-box {
						.other-message{
							background-color: $dark-card-background;
							border-color: $dark-card-border;
						}
					}
				}
			}		
			.chat-menu{
				background-color: $dark-card-background;
				border-color: $dark-card-border;
				.user-profile {
					.follow{
						border-color: $dark-card-border;
						.follow-num{
							color:$dark-all-font-color;
						}
					}
					.image{
						.icon-wrapper{
							background-color:$dark-body-background;
							box-shadow:none;
						}
					}
					.digits{
						border-color: $dark-border-color ;
					}
				}
			}	
			
			
		}

		.social-status {
			form {
				.form-group {
					.form-control-social{
						background-color: $dark-card-background;
						border-color: $dark-card-border;
					}
				}
			}
		}

		// search page //
		.search-page {
			.search-form {
				.form-group {
					.input-group-text{
						background-color: $dark-body-background;
					}
				}
			}
		}

		// ecommerce //
		.product-wrapper{
			&.sidebaron {
				.product-sidebar {
					.filter-section {
						.card {
							.filter-cards-view{
								background-color: $dark-card-background;
								border: none;
							}
							.left-filter{
								border: none;
							}
						}
					}
				}
			}
		}
		.product-page-main {
			.pro-group{
				&:nth-child(n+2){
					border-color: $dark-card-border;
				}
			}
		}

		.prooduct-details-box {
			.media{
				border-color:$dark-card-border;
			}
		}
		.invoice {
			.invo-header{
				border-color:$dark-card-border;
			}
		}
		.order-history {
			table {
				.qty-box {
					.input-group{
						border-color: $dark-card-border;
					}
				}
			}
		}


		
		
		
		
		
		
		

		@media only screen and (max-width: 1199px){
			.sm{
				background-color: $dark-card-background;
				border: 1px solid $dark-card-border;
			}
			.pixelstrap{
				.mobile-back{
					border-bottom: 1px solid $dark-card-border;
				}
				ul{
					li{
						border-top: 1px solid $dark-card-border;
					}
					a{
						background: $dark-card-background;
						&:hover,
						&:focus,
						&:active{
							background: $dark-card-background;
						}
					}
				}
			}
			.chat-menu {
				border-top: 1px solid $dark-card-border;
				background-color: $dark-card-background;
			}
			.error-wrapper{
				.maintenance-icons{
					li{
						i{
							color: $dark-color;
						}
					}
				}
			}
			.widget-joins {
				.border-after-xs{
					&:after{
						background-color: $dark-card-border;
					}
				}
			}
		}

		@media only screen and (max-width: 991px){
				.page-wrapper{
						.page-main-header{
							.main-header-right{
								.nav-right{
									>ul{
										border-top: 1px solid $dark-card-border;
									}
								}
							}
						}
					}

			}
		.page-wrapper.horizontal-wrapper,.compact-wrapper {
			/* Main Header start */
			.page-main-header {
				background-color: $dark-body-background;
				.main-header-right {
					background-color: $dark-card-background;
					box-shadow: 0 0 18px 3px rgba(42, 50, 61, 1);
					
					
					li {
						i {
							color: $light-gray;
						}
					}
				
					.nav-right {
						> ul{
							&.click-menu{
								> li{
									ul{
										&.submenu{
                      						background-color: $dark-card-background;
											box-shadow: none;
											li{
												a{
													color: $white;
												}
											}
                    					}
									}
								}
							}
							> li{
								// border: 1px solid $dark-card-border;
								&:before{
									background-color: $dark-body-background;
								}
								svg{
									stroke: $white;
								}
								&:last-child{
									border: none;
								}
								&:first-child{
									border: none;
								}
							}
						}
						// ul {
						// 	li {
						// 		.mode {
						// 			.mode-sun{
						// 				background-color: $dark-card-background
						// 			}
						// 		}
						// 	}
						// }
						.nav-menus {
							li {
								.media {
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									border-color: $dark-card-border;
									p{
										color: $white !important;
									}
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid $dark-card-border;
										span {
											color: $dark-small-font-color;
										}
										&:hover{
											background-color: #282e38;
										}
									}
									.bg-light {
										background-color: #282e38 !important;
										color: $dark-all-font-color !important;
									}
								}
								.language-dropdown {
									li {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}

						}
						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;
									svg{
										path, line, circle{
											color: $dark-all-font-color !important;
										}
									}
									&:hover{
										color: $primary-color;
										svg{
											line, path, circle{
												color: $primary-color !important;
											}
										}
									}
								}
							}
						}
						> ul {
							> li {
								.media {
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid $dark-card-border;
										span {
											color: $dark-small-font-color;
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.chat-dropdown,.notification-dropdown{
							li{
								color: $white;
								+li{
									border-top: 1px solid $dark-card-border;
								}
							}
						}
					}
					.mega-menu-header{
						.vertical-menu-main{
							.main-nav{
								.main-menu{
									li{
										background-color: $dark-card-background;
									}
								}
							}
						}
					}


					.left-menu-header {
						ul {
							li {
								.search-form {
									input{
										color: $dark-all-font-color;
									}
								}
							}
						}
					}
				}
				.main-header-left {
					background-color: $dark-card-background;
					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}
							.image-light{
								display: block;
							}
						}
					}
				}
				@media only screen and (max-width: 991px){
					.main-header-right{
						.nav-right {
							> ul{
								background-color: $dark-card-background;
								box-shadow: 0 2px 2px 2px $dark-card-border;
							}
						}
					}
					
				}
				
			}
			
			/* Main Header ends */
			.page-body-wrapper {
				/* body start*/
				.page-body{
					.topper-lists{
						table {
							tbody{
								tr{
									td{
										p {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
					}
					.header-small {
						color: $dark-small-font-color;
					}
					.statistics{
						p {
							color: $dark-small-font-color;
						}
					}
					.feather-main, .professor-table {
						.media-body, .professor-block{
							p {
								color: $dark-small-font-color;
							}
						}
					}
					.logs-element {
						span {
							+ span {
								color: $dark-small-font-color
							}
						}
					}
					.progress-block{
						.progress-title{
							span{
								+ span {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.ecommerce-icons{
						div{
							span{
								color: $dark-small-font-color;
							}
						}
					}
					.new-users, .recent-notification{
						.media{
							.media-body {
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.progress-media{
						.media{
							.media-body {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.progress-change{
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.topper-lists{
						table{
							tbody{
								tr{
									td {
										border-top: none !important;										
									}
									&:last-child{
										td{
											border-bottom: none;
										}
									}
								}
							}
						}
					}
					.notifiaction-media{
						.media{
							.media-body{
								.circle-left {
									border: 5px solid #334154;
								}
							}
						}
					}
					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid $dark-card-border;
							&:last-child{
								border-bottom: none;
							}
						}
					}
					.professor-table{
						table{
							tbody{
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}
					.number-widgets{
						.media{
							.media-body{
								h6 {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.activity{
						.media {
							.gradient-round{
								&.gradient-line-1,
								&.small-line,
								&.medium-line{
									&:after {
										background-color: $dark-card-border;
									}
								}
							}
							.media-body{
								h6{
									span{
										color: $dark-small-font-color;
									}
								}
							}
						}
					}
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
						
					}
				}				
				.custom-card {
					.card-header {
						img{
							background-color: $black;
							opacity: 0.8;
						}
					}
					.profile-details {
						h4{
							color: $dark-all-font-color;
						}
					}
				}
				.page-header {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}
					.breadcrumb{
						li{
							color: $dark-all-font-color;
						}
					}
					.breadcrumb-item {
						a{
							color: $dark-all-font-color;
						}
						&.active {
							color: $dark-small-font-color;
						}
					}
				}
				.page-body {
					background-color: $dark-body-background;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
						border: 1px solid $dark-card-border;
						.chart-block{
							#bar-chart2{
								svg {
									> rect {
										fill: $dark-card-background;
									}
									> g {
										text{
											fill: $dark-all-font-color;
										}
									}
								}
							}
							.word-tree{
								svg {
									> g {
										> rect {
											fill: $dark-card-background;
										}
										> text{
											fill: $dark-all-font-color;
										}
									}
								}
							}
						}
						.card-header {
							background-color: $transparent-color;
							// border-bottom: 1px solid $dark-card-border;
							> span {
								color: $dark-all-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								background-color: $dark-card-background;
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-card-border;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 1px solid $dark-card-border;
									border-top: none;
									background-color: $dark-card-background;
								}
								.border-bottom-primary {
									th{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $dark-card-background;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-card-border;
							}
							tbody {
								.border-bottom-primary{
									th, td{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table > :not(:last-child) > :last-child > *{
							border-color:$dark-card-border;
						}
						.table[class*='bg-']{
							th, td{
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color:rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-card-border !important;
							}
						}
						.table-inverse{
							tbody {
								tr{
									&:nth-of-type(odd){
										&:hover {
											td{
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-card-border;
							}
						}
						.table-styling{
							thead, tbody{
								th, td{
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $white !important;
						}
						.b-l-light {
							border-left: 1px solid $dark-card-border !important;
						}
						.ct-grid {
							stroke: $dark-card-border;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {						
							background-color:$dark-card-border;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-card-border;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-card-border;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-card-border;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark {
							color: $white !important;
							border: 1px solid $dark-card-border;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-card-border !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#gantt_chart{
								svg{
									g{
										rect{
											&:first-child{
												fill: $dark-body-background;
											}
										}
									}
								}
								 rect {
									&:nth-child(6){
										fill: $dark-body-background;
									}
								}
							}
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-card-border !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-card-border;
							}
						}
						.img-thumbnail {
							background-color: $dark-card-border;
							border: 1px solid $dark-card-border;
						}
						.dataTables_wrapper {
							button {
								color: $black;
							}
							.btn-danger, .btn-success {
								color: $white;
							}
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-card-border;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate{
								border: 1px solid $dark-card-border;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-card-border;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: $dark-datatable-sorting;
											}
										}
									}
									tr.odd {
										background-color: $dark-datatable-odd;
										> .sorting_1 {
											background-color: $dark-datatable-sorting;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: $dark-datatable-sorting-even;
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-card-border;
								thead {
									th, td {
										border-bottom: 2px solid $dark-card-border;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-card-border;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
									&.current,
									&:active{
										border-color: $primary-color;
									}
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-card-border;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: $dark-even-hover-sorting;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: $dark-datatable-sorting;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-card-border;
									border-right: 1px solid $dark-card-border;
									&:first-child {
										border-left: 1px solid $dark-card-border;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-card-border;
								}
							}
						}
						.page-link {
							border: 1px solid $dark-card-border;
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-card-border;
							}
						}
						.page-link {
							color: $dark-all-font-color;
							background-color: $dark-card-background;
						}
						.page-item {
							&:hover{
								.page-link{
									background-color: $dark-body-background;
								}
							}
						}
						.page-item.active {
							.page-link {
								background-color: $dark-body-background;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
						}
						.product-table{
							#API-2_wrapper{
								#API-2{
									tbody{
										td{
											span, p{
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6{
								color: $dark-small-font-color;
							}
						}
						.border-tab.nav-tabs {
							border-bottom: 1px solid $dark-card-border;
							.nav-item {
								.nav-link{
									color: $dark-all-font-color;
								}
							}
							.nav-link{
								&.active,
								&:focus,
								&:hover{
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal{
							.br-widget{
								a{
									background-color: $dark-card-border;
									&.br-active,
									&.br-selected{
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget{
								a{
									border: 2px solid $dark-card-border;
									background-color: $dark-card-background ;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected{
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget{
								a{
									&:after{
										color: $dark-card-border;
									}
									&.br-selected,
									&.br-active{
										&:after{
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo{
							border: 1px solid $dark-card-border;
						}
						.search-form{
							input{
								border: 1px solid $dark-card-border;
								color: $dark-all-font-color;
								background-color: $dark-body-background;
							}
							.form-group{
								&:before {
									background:$dark-card-background;
								}
							}
						}
						.cd-timeline-content{
							background-color:$dark-card-background;
							border-color:$dark-card-border;						
							&::before{
								border-left: 7px solid $dark-card-border;
							}
						}
						.cd-timeline-block{
							&:nth-child(even) {
								.cd-timeline-content{
									&::before {
										border-right-color: $dark-card-border;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb{
							&.bg-white {
								background-color: $dark-card-background !important;
							}
						}
						.user-status{
							table{
								td, th{
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart{
							svg{
								opacity: 0.5;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-card-border;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-card-border;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-card-border;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-card-border;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-border;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-card-border;
							input {
								background-color: $dark-body-background;
								border-color: $dark-card-border;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-card-border;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-card-border;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: $dark-datatable-sorting;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-card-border;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator{
							// border: 1px solid $dark-card-border;
							border-color: $dark-card-border;
						}
					}
					.default-according{
						li{
							.text-muted{
								color: $dark-all-font-color!important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li{
							button{
								&:hover
								{
									color: $primary-color!important;
								}
							}
						}
					}
					.nav-list{
						.nav-list-disc{
							li{
								a{
									color: $dark-all-font-color;
								}
								&:hover{
									color: $primary-color;
									a{
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown{
						.onhover-show-div{
							background-color: $dark-body-background;
							box-shadow: 0 0 2px 2px $dark-card-border;
							.navs-icon{
								li{
									p{
										color: $white;
									}
									a{
										svg{
											path, line, polyline, polygon, rect{
												color: $dark-all-font-color !important;
											}
										}
										&:hover{
											svg{
												path, line, polyline, polygon, rect{
													color: $primary-color !important;
												}
											}
										}
									}
								}
							}
						}
					}
					.onhover-show-div{
						border-color:$dark-card-border;
					}
					.onhover-dropdown{
						&:hover {
							.onhover-show-div{
								&:before{
									border-bottom: 7px solid $dark-card-border;
								}
							}
						}
					}
					.default-according {
						.card {
							box-shadow: 0 0 1px 1px rgb(55, 66, 82) !important;
							border: none !important;
							background-color: $dark-card-background !important;
							.btn-link {
								color:$dark-all-font-color;
								border:none;							
							}
							.card-body {
								color: $dark-small-font-color;
								border: 1px solid $dark-card-border;
							}
						}
					}
					.border {
						border: 1px solid $dark-card-border !important;					
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-card-border;
									&:first-child {
										border-right: 1px solid $dark-card-border;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-card-border;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.blog-list {
						.blog-details {
							h6{
								border-color:$dark-card-border;
							}
						}
					}
					.cke_wysiwyg_frame, .cke_wysiwyg_div{
						background-color: $dark-card-background;
					}
				
					.add-post {
						form {
							.form-group {
								.form-control{
									border-color: $dark-card-border;
								}
							}
						}
						.cke_top{
							background-color: $dark-card-background;
						}

						 #cke_text-box{
							 border-color: $dark-card-border;
						 }
					}
					.comment-box {
						h4{
							border-color:$dark-card-border;
						}
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-card-border;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-card-border;
								}
							}
						}
						hr{
							border-top: 1px solid $dark-card-border;
							background-color: $dark-card-border;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-card-border;
							color: $sidebar-submenu-font-color;
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px $dark-card-background;
							}
							em{
								display: none;
							}
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-card-border;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-card-border;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-card-border;
								}
							}

						}
					}
					.form-control,select {
						background-color: $dark-card-background;
						color: $dark-all-font-color;
						// border: 1px solid $dark-card-border;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-card-border;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								border-color:$dark-card-border;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-card-border;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-card-border;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.cke_top{
							border-color: $dark-card-border !important;
						}
						.cke_chrome{
							border-color: $dark-card-border !important;
							background-color: $dark-card-background ;
							color: $dark-all-font-color;
						}
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-card-border;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
									.media{
										&:hover{
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-card-border;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-card-border;
								}
							}
						}
						.email-wrapper {
							.emailread-group{
								&:nth-child(n+2){
									border-color:$dark-card-border;
								}
							}							
						}
						
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-card-border;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-card-border;
							}
						}
						&:before,
						&:after {
							background-color: $dark-card-border;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-card-border !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-card-border;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-card-border;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-card-border;
							border: 1px solid $dark-card-border;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-card-border;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-card-border;
						}
					}
					.page-builder {
						.btn-grid{
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-card-border;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column{
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen{
							color: $dark-small-font-color;
							border-right: 1px solid $dark-card-border;
						}
						.ge-canvas.ge-editing {
							.row{
								background-color: $dark-body-background;
							}
							.column{
								border: 1px solid $dark-card-border;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer{
								.ge-details{
									input{
										background-color: $dark-body-background;
										border-color: $dark-card-border;
										color: $dark-small-font-color;
									}
									.btn-group{
										a{
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor{
							color: $dark-all-font-color;
						}
					}
					.search-page {
						.info-block{
							+ .info-block{
								border-top: 1px solid $dark-card-border;
							}
						}
					}
					.card-absolute{
						.bg-primary, .bg-secondary{
							h5{
								color: $white;
							}
						}
					}
					.timeline-small{
						.media{
							.timeline-round{
								&.timeline-line-1,
								&.small-line,
								&.medium-line{
									&:after {
										background-color: $dark-card-border;
;
									}
								}
							}
						}
					}
					/* body end*/
				}
				footer {
					p{
						color: $dark-all-font-color;
					}
					border-top: 1px solid $dark-card-border;
					background-color: $dark-card-background;
				}
				.custom-select, .custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}
				.footer-fix{
					background-color: $dark-card-background;
					border-top: 1px solid $dark-card-border;
				}
			}
			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;
				h2 {
					background-color: $dark-card-background;
				}
			}
			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}
				.chat-menu {
					border-left: 1px solid $dark-card-border;
					.nav-tabs {
						border-bottom: 1px solid $dark-card-border;
						.nav-item {
							.nav-link {
								&.active {
									color: $dark-all-font-color !important;
								}
							}
						}
					}				
				}
				.status {
					color: $dark-small-font-color;
					p {
						color: $dark-all-font-color !important;
					}
				}
				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid $dark-card-border;
							.chat-menu-icons {
								li {
									a {
										i {
											color: $dark-small-font-color;
										}
									}
								}
							}
							img{
								box-shadow: 1px 1px 4px 1px $dark-body-background;
							}
						}
						.chat-msg-box {
							.my-message {
								border: 1px solid $dark-card-border
							}
							.message {
								color: $dark-all-font-color;
							}
							.other-message {
								background-color: $dark-body-background;
							}
						}
						.chat-message {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
							.input-group {
								.form-control {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.chat-history {
					.call-icons {
						ul {
							li {
								border: 1px solid $dark-card-border;
								a {
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
				.bitcoin-chat{
					.chat{
						.chat-msg-box{
							.message{
								&.my-message {
									background-color: #1c222b;
									.chat-user-img {
										border: 2px solid $dark-card-border;
									}
								}
								&.other-message {
									.chat-user-img {
										border: 2px solid $dark-card-border;
									}
								}
							}
						}
					}
				}
			}
			pre {
				background-color: $dark-card-border;
				color: $dark-all-font-color;
			}
			.scorlled {
				background-color: $dark-body-background;
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-card-border;
			}
			.input-group-solid {
				.input-group-text, .form-control {
					background: $dark-body-background;
					border: 1px solid $dark-card-border;
					color: $dark-all-font-color;
				}
			}
			.semilight-bg-color, .header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: $dark-card-background;			
				border:1px solid $dark-card-border;
				color: $dark-all-font-color;
				&.active {
					background-color: $primary-color !important;
					border-color: $primary-color;
					color: $white;
				}
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.authentication-box {
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-card-border;
								}
								label {
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									color: $dark-all-font-color;
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
									}
								}
							}
						}
					}
					h3, h4, h6{
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4, h3 {
						color: $white;
					}
					h6 {
						color: $dark-small-font-color;
					}
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input[type=text],
								input[type=password] {
									background-color: $dark-body-background;
									border: 1px solid $dark-card-border;
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-card-border;
						}
						&:nth-child(3) {
							i {
								color: $dark-card-border;
							}
						}
					}
				}
			}
			.modal-content {
				background-color: $dark-card-background;
				border-color: $dark-card-border;
				.modal-header {
					border-bottom: 1px solid $dark-card-border;
					background-color: $dark-card-background;
					border-color: $dark-card-border;
					.close {
						color: $dark-small-font-color;
					}

				}
				.modal-footer {
					background-color: $dark-card-background;
					border-color: $dark-card-border;
				}
			}
			
			
			.btn-close{
				filter:invert(1);
			}
			.comingsoon {
				background-blend-mode:overlay;
				background-color:rgba(54, 64, 74, 0.9);
				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}
					.countdown {
						border-top: 1px solid $dark-card-border;
						border-bottom: 1px solid $dark-card-border;
						.title {
							color: $dark-all-font-color;
						}
					}
					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
				}
			}
			.theme-form {
				.login-divider{
					border-top: 1px solid $dark-card-border;
					&:before {
						background: $dark-card-border;
						color: $dark-all-font-color;
					}
				}
			}
			.authentication-main {
				background-color: $dark-card-border;
				.auth-innerright {
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}
						.reset-password-link {
							color: $dark-small-font-color;
						}
						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}
								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-card-border;
								}
							}
							.opt-box {
								background-color: $dark-card-border;
							}
						}

					}
					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-small-font-color;
						}
						h3{
							color: $dark-all-font-color;
						}
						.card {
							background-color: $dark-card-background;
							.theme-form {
								.form-group {
									input[type=text],
									input[type=password] {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
										color: $dark-all-font-color;
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-card-border;
										}
									}
								}
							}
						}
					}
				}
			}
			.vertical-menu-main{
				background-color: $dark-card-background;
			}
			.pixelstrap {
				a{
					color: $dark-all-font-color;
					&:focus,
					&:active,
					&:hover{
						color: $dark-all-font-color;
					}
				}
				> {
					li {
						> a.highlighted{
							&:before
							{
								background-color: $dark-card-background;
							}
							&:after
							{
								background-color: $dark-card-border;
							}
						}
					}
				}
			}
			.border-top{
				border-top: 1px solid $dark-card-border !important;
			}
			.table{
				td,th{
					border-top: 1px solid $dark-card-border;
				}
			}
			.breadcrumb{
				background-color: $dark-body-background;
			}
			.table-bordered{
				border: 1px solid $dark-card-border;			
			}
			.table-border-horizontal{
				tr,th,td{
                      border-top: 1px solid $dark-card-border;
				}
			}
			thead, tbody, tfoot, tr, td, th{
				border: 1px solid $dark-card-border;
			}
			.btn-light{
				background-color: $dark-body-background !important;
                border-color: $dark-card-border !important;
                color:$dark-all-font-color;
			}
			.profile-greeting{
				.btn-light{
					background-color: $light-gray !important;
					border-color:$light-gray !important;		
					color: $primary-color !important;	
				}
			}
			.note-editor{
				&.note-frame{
					.note-statusbar{
						background-color: $dark-body-background;
                        border-top: 1px solid $dark-card-border;
					}
					.note-status-output{
						border-color:$dark-card-border;
					}
				}
			}
			.contacts-tabs{
				.nav-pills{
					  border-right: 1px solid $dark-card-border;
					.nav-link{
						+.nav-link{
							border-top: 1px solid $dark-card-border;
						}
					}
				}
			}
			.list-persons{
				.profile-mail{
					.media{
						.media-body{
							ul{
								li{
									+li{
										border-left: 2px solid $dark-card-border;
									}
								}
							}
						}
					}
				}
			}
			.blog-box{
				&.blog-shadow{
                  .blog-details{
                  	h4{
                  		color: $dark-all-font-color;
                  	}
                  }
				}
			}
			@media screen and (max-width: 1366px){
					.earning-card{
						.card-body{
							>.row{
								.border-right{
									border-bottom: 1px solid $dark-card-border !important;
								}
							}
						}
					}
					.chart_data_left,.chart_data_right{
						.card-body{
							.chart-main{
								.media{
                                 border-right: 1px solid $dark-card-border;
								}
							}
						}
					}
				}
			@media (min-width: 1200px){
				.pixelstrap{
					ul {
						background: $dark-body-background;
						border: 1px solid $dark-card-border;
						a{
							&:hover,
							&:focus,
							&:active,
							&.highlighted{
								color: $primary-color;
							}
							&.current{
								background-color: $dark-body-background;
								color: $primary-color;
							}
						}
					}
				}
			}
			.mega-menu {
				.title{
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-card-border;
				}
				.galleria-list{
					.galleria{
						> div {
							.username{
								color: $dark-all-font-color;
								small{
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
				.list-unstyled{
					div{
						a{
							&:hover{
								color: $primary-color;
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: $dark-card-background;
					.btn-link{
						background-color: $dark-card-background;
						border: 1px solid $dark-card-border;
						color: $white;
					}
					.text-muted{
						color: $dark-small-font-color !important;
					}
				}
				.bg-primary{
					.btn-link{
						background-color: $primary-color;
						border: 1px solid $primary-color;
					}
				}
				.bg-secondary{
					.btn-link{
						background-color: $secondary-color;
						border: 1px solid $secondary-color;
					}
				}
			}
			.collapse{
				.card-body{
					background-color: $dark-card-background;
				}
			}
			@media screen and (max-width: 1660px){
				.chat-box{
					.chat-history{
						.call-icons{
							ul{
								li{
									border: 1px solid $dark-card-border;
								}
							}
						}
						.total-time{
							h2{
								color: $dark-all-font-color;
							}
						}
					}
				}
				.caller-img{
					img{
						opacity: 0.7;
					}
				}
				.chat-box {
					.chat-history {
						.call-content {
							>div{
								background-color: $dark-card-background;
								background-blend-mode: overlay;
							}
						}
					}
				}
			}
			
			.pixelstrap{
				ul{
					background: $dark-card-background;
				}



			


			}
			@media only screen and (max-width: 575px){			
				.widget-joins {
					.border-after-xs {
						&:after {

							background-color: $dark-card-border;
						}
					}
				}
			}
		}
		.lg-backdrop {
			background-color: $dark-body-background;
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}
		.drag {
			background-color: $dark-card-background;
			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-card-border;
				border: 1px dotted $dark-card-border;
				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-card-border;
					color: $dark-all-font-color;
				}
				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}
		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-card-border;
			}
			p {
				color: $dark-small-font-color;
			}
			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-card-border;
			}
			.radio, .checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-card-border;
					}
				}
			}
		}
		.select2-container--default{
			.select2-selection--multiple, .select2-selection--single{
				background-color: $dark-body-background;
				border: 1px solid $dark-card-border !important;
			}
			.select2-search--inline{
				.select2-search__field{
					color: $dark-all-font-color;
				}
			}
			.select2-selection--single{
				.select2-selection__rendered{
					color: $dark-all-font-color;
				}
			}
			.select2-search--dropdown {
				.select2-search__field{
					background-color: $dark-body-background;
					border: 1px solid $dark-card-border;
					color: $dark-all-font-color;
				}
			}
		}
		.form-bookmark {
			.form-group {
				.select2-container {
					.select2-selection--single{
						border-color: $dark-all-font-color
					}
				}
			}
		}
		.select2-dropdown{
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: $primary-color !important;
				color: $primary-color !important;
			}
			.form-control-secondary {
				border-color: $secondary-color !important;
				color: $secondary-color !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $light-font !important;
				color: $light-font !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill{
				background-color: $primary-color !important;
				color: $white !important;
			}
			.form-control-secondary-fill{
				background-color: $secondary-color !important;
				color: $white !important;
			}
			.form-control-success-fill{
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill{
				background-color: $light-font !important;
				color: $white !important;
			}
			.form-control-warning-fill{
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill{
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill{
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled{
			.select2-selection--single{
				background-color: $dark-card-border;
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-card-border;
				}
				input[type=text], input[type=email], input[type=search],
				input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local], input[type=time], input[type=datetime-local], input[type=month], input[type=week],
				input[type=url], input[type=file], select {
					border-color: $dark-card-border;
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}
				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
				textarea {
					border-color: $dark-card-border;
				}
			}
			.form-divider{
				border-top: 1px solid $dark-card-border;
				&::before{
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.CodeMirror{
			background: $dark-card-background;
			border: 1px solid $dark-card-border;
			color: $dark-all-font-color;
			.CodeMirror-code{
				pre{
					background: $dark-card-background;
				}
			}
		}
		.editor-statusbar{
			border-color:$dark-card-border;
		}
		.editor-toolbar{
			border-top: 1px solid $dark-card-border;
			border-left: 1px solid $dark-card-border;
			border-right: 1px solid $dark-card-border;
			a{
				color: $dark-all-font-color !important;
				&:hover,
				&.active{
					background: $dark-card-border;
				}
			}
			i.separator{
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}
		}
		.editor-toolbar.disabled-for-preview {
			a{
				&:not(.no-disable){
					background: $dark-card-border;
				}
			}
		}
		.editor-preview {
			background-color: $dark-card-background;
		}
		.editor-toolbar.fullscreen, .editor-preview-side{
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}
		.u-step{
			background: $dark-card-border;
			&.active,
			&.current{
				background: $primary-color;
				color: $white;
			}
		}
		.u-step-title, .u-pearl-title{
			color: $dark-all-font-color;
		}
		.u-step-number{
			background-color: $dark-card-background;
		}
		.u-pearl{
			&:before{
				background-color: $dark-card-border;
			}
		}
		.u-pearl-number, .u-pearl-icon{
			background: $dark-body-background;
			border: 2px solid $dark-card-border;
		}
		.u-pearl.disabled {
			.u-pearl-icon, .u-pearl-number{
				background: $dark-card-border;
				border: 2px solid $dark-card-border;
			}
			&:after {
				background-color: #334053;
			}
		}
		.u-pearl.error{
			&:after{
				background-color: $dark-body-background;
			}
		}
		.note-editor.note-frame{
			border-color: $dark-card-border;
			.note-editing-area {
				.note-editable{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.swal-modal{
			background-color: $dark-card-background;
			.swal-title{
				color: $dark-all-font-color;
			}
			.swal-text{
				color: $dark-small-font-color;
			}
			.swal-content__input{
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}
		.nav-tabs{
			border-bottom: 1px solid $dark-card-border;
			.nav-link{
				&.active{				
					color: $dark-all-font-color;
					border-color: $dark-card-border;
				}
				&:hover,
				&:focus{
					border-color: $dark-card-border;
				}
			}
			.nav-item.show{
				.nav-link{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-card-border;
				}
			}
		}	
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link{
					&.active,
					&.focus,
					&.hover{
						border-color: $dark-card-background $dark-card-border $dark-card-border;
					}
				}
			}
		}
		.nav-success,.nav-pills.nav-success  {
			.nav-link{
				color:$dark-all-font-color;
			}
		}
		.nav-pills.nav-success .nav-link
		.border-tab.nav-left, .border-tab.nav-right {
			.nav-link{
				color: $dark-all-font-color;
				&.active{
					color: $primary-color;
				}
			}
			.show {
				> .nav-link{
					color: $primary-color;
				}
			}
		}
		.border-tab.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					border-left-color: $secondary-color;
					color: $secondary-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $secondary-color;
				color: $secondary-color !important;
			}
			.nav-item.show{
				color: $secondary-color !important;
				border-left-color: $secondary-color;
			}
		}
		.border-tab.nav-left.nav-success{
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show{
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.border-tab.nav-right.nav-info{
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					color: $light-font !important;
				}
			}
			.show > .nav-link {
				border-left-color: $light-font;
				color: $light-font !important;
			}
			.nav-item.show{
				color: $light-font !important;
				border-left-color: $light-font;
			}
		}
		.border-tab.nav-secondary {
			.nav-link{
				&.active,
				&:focus,
				&:hover{
					color: $secondary-color !important;
				}
			}
			.nav-item{
				&.show{
					color: $secondary-color !important;
				}
			}
		}
		.border-tab.nav-success {
			.nav-link{
				&.active,
				&:focus,
				&:hover{
					color: $success-color !important;
				}
			}
			.nav-item{
				&.show{
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider{
			border-top: 1px solid $dark-card-border;
		}
		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-card-border;
			.icon-title {
				color: $dark-all-font-color;
			}
			span {
				color: $dark-small-font-color;
			}
			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-card-border;
				}
			}
			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}
		code {
			background-color: $dark-body-background;
			border-radius: 2px;
		}
		#cd-timeline{
			&::before {
				background: $dark-card-border;
			}
		}
		.timeliny{
			border-top: 1px solid $dark-card-border;
			border-bottom: 1px solid $dark-card-border;
			&::before{
				background-color:$dark-card-border
			}
			.timeliny-dot{
				background-color:$dark-card-background;
				border: 1px solid $dark-card-border;
				&::before {
					color: $dark-all-font-color;
				}
			}
			.timeliny-timeline{
				.timeliny-timeblock{
					&.active{
						.timeliny-dot{
							&::before{
								color: $dark-all-font-color;
							}
							&:after{
								background-color:$dark-body-background;
								border-color:$dark-card-border;
								color:$dark-all-font-color;
							}
						}

						
					}
					&:not(.inactive):not(.active){
						.timeliny-dot{
							&:hover {
								background-color: $primary-color;
								&::before{
									color: $dark-all-font-color;
								}
							}
						}
					}
					.timeliny-dot{
						&:hover{
							&::after{
								background-color: $dark-card-background;
								border: 1px solid $dark-card-border;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			

			
		}
		.todo{
			.todo-list-wrapper{
				#todo-list{
					li {
						.task-container{
							background: $dark-card-background;
							border-bottom: 1px solid $dark-card-border;
							.task-label{
								color: $dark-all-font-color;
							}
							&:hover{
								h4{
									color: $white;
								}
							}
							.task-action-btn{
								.action-box{
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-card-border;
									}
								}
							}
						}
						&.completed{
							.task-container{
								.task-label{
									color: $primary-color;
								}
								.complete-btn{
									&:hover{
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper{
						textarea{
							background-color: $dark-card-background;
							border: 1px solid $dark-card-border;
							color: $dark-all-font-color;
						}
					}
				}

			}
		}	
		.introjs-helperLayer{
			background-color: rgba(54,64,74,0.9);
			border: rgba(255,255,255,0.5);
		}
		.jstree-default {
			.jstree-node, .jstree-icon{
				color: #455869 !important;
			}
			.jstree-anchor{
				color: $dark-small-font-color;
			}
			.jstree-clicked{
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered{
				background-color: $dark-body-background;
				border: 1px solid $dark-card-border;
			}
		}
		span.twitter-typeahead{
			.tt-menu{
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
			.tt-suggestion{
				color: $dark-all-font-color;
				&:hover,
				&:focus{
					background-color: $dark-card-background;
				}
			}
		}
		.typography{
			small{
				color: $dark-all-font-color;
			}
		}
		.blockquote-footer{
			color: $dark-small-font-color;
		}
		.code-box-copy{
			pre{
				background-color: $dark-body-background;
				code{
					background-color: $dark-body-background;
				}
			}
			pre[class*="language-"]{
				border: 1px solid $dark-card-border;
			}
			.code-box-copy__btn{
				background-color: $dark-body-background;
				border: 1px solid $dark-card-border;
				color:$white;
			}
			code[class*=language-], pre[class*=language-]{
				text-shadow: 0px 1px $black;
				::selection{
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}
		table.fixedHeader-floating{
			background-color: $dark-body-background;
		}
		.dt-button-info{
			background-color: $dark-card-background;
			border: 1px solid $dark-card-border;
			h2{
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-card-border;
			}
		}
		
		#keytable_wrapper, #column-selector_wrapper, #auto-fill_wrapper, #custom-button_wrapper, #class-button_wrapper, #keyboard-btn_wrapper, #multilevel-btn_wrapper, #pagelength-btn_wrapper, #basic-colreorder_wrapper, #state-saving_wrapper, #real-time_wrapper, #basic-fixed-header_wrapper, #fixed-header-footer_wrapper, #export-button_wrapper, #excel-cust-bolder_wrapper, #cust-json_wrapper, #basic-key-table_wrapper, #focus-cell_wrapper, #responsive_wrapper, #new-cons_wrapper, #show-hidden-row_wrapper, #basic-row-reorder_wrapper, #full-row_wrapper, #rest-column_wrapper{
			.dataTables_paginate{
				border: none;
			}
		}
		#example-style-8_wrapper{
			table.dataTable.stripe, table.dataTable.display{
				tbody {
					tr.odd{
						background-color: $dark-card-border;
					}
				}
			}
		}
		.error-wrapper{
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content{
				color: $dark-all-font-color;
			}
		}
		.b-light{
			border:1px solid $dark-card-border !important;
		}
		.modal-content{
			background-color: $dark-card-background;
			.modal-header{
				border-bottom: 1px solid $dark-card-border;
			}
			.ui-front{
				.form-control{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-card-border;
				}
			}
		}
		.stepwizard {
			.stepwizard-row{
				&:before{
					background-color: $dark-small-font-color;
				}
			}
		}
		.modal{
			.theme-close{
				background-color: $dark-card-background !important;
				filter:invert(0);
			}
		}
		.token.atrule, .token.attr-value, .token.keyword {
			color: $light-font;
		}
		.token{
			&.boolean, &.constant, &.deleted, &.number, &.property, &.symbol, &.tag{
				color: $danger-color;
			}
		}
		.loader-box{
			&.card-loader{
				background-color: $dark-card-background;
			}
		}
		.my-gallery{
			&.gallery-with-description{
				img {
					border: 1px solid $dark-card-border !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse{
				color: $dark-all-font-color;
			}
		}
		.alert-theme{
			span{
				+ span{
					+ span{
						border-left: 5px solid $primary-color;
						background-color: $dark-body-background;
						border-radius: 4px;
					}
				}
			}
			i{
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}
		.user-card {
			.user-deatils{
				h6 {
					color: $dark-small-font-color;
				}
			}
			.card-footer{
				> div{
					&:first-child {
						border-right: 1px solid $dark-card-border;
					}
				}
				.user-footer{
					h6 {
						color: $dark-small-font-color;
					}
					svg{
						path, rect {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}
		.dashboard-chat {
			.chat{
				.media {
					.media-body{
						.message-main{
							p {
								background-color: $dark-body-background;
							}
							&.smiley-bg {
								background-color: $dark-body-background;
							}
						}
					}
				}
				.right-side-chat{
					.media-body{
						p{
							&:before {
								border-left: 7px solid $dark-card-border;
							}
						}
					}
				}
				.left-side-chat{
					.media-body{
						p{
							&:before{
								border-right: 7px solid $dark-card-border;
							}
						}
					}
				}
			}
		}
		.selling-update {
			border: 2px solid $dark-card-border;
		}
		.bitcoin-accordion {
			.card{
				.media-accordion{
					.media:nth-child(2) {
						background-color: #1c222b;
					}
					.media{
						.media-body{
							p {
								color: $dark-small-font-color;
							}
						}
						+ .media{
							border-top: 1px solid $dark-card-border;
						}
					}
				}
			}
		}
		.server-card-bg{
			background-color: #181f27 !important;
		}
		.server-widgets {
			.bottom-server {
				h5{
					.second-color {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.select2-drpdwn-project{
			.form-control{
				background-color: $dark-card-background !important;
				border-top: none !important;
				border-left: none !important;
				border-right: none !important;
			}
		}
		.tabs-project{
			.nav-item{
				.nav-link{
					color: $dark-all-font-color;
				}
			}
		}
		.current-progress, .order-history{
			table{
				tr{
					td, th{
						border-top: none !important;
					}
				}
			}
			.title-orders {
				background-color: $dark-body-background;
			}
		}
		.navigation-option{
			ul{
				li {
					a {
						color: $dark-all-font-color;
					}
					&:hover{
						background-color: $dark-body-background;
					}
				}
			}
		}
		.product-box {
			.product-details {
				h6 {
					color: $dark-all-font-color;
				}
				span {
					color: $dark-small-font-color;
				}
			}
			.product-img {
				.product-hover {
					ul {
						li{
							background-color: $dark-card-background;
							&:hover{
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}
		.browse{
			.browse-articles{
				ul{
					li{
						a {
							color: $dark-all-font-color;
						}
						&:hover{
							background-color: $dark-body-background;
						}
					}
				}
			}
		}
		@each $btn-name, $btn-color in (primary, $primary-color),
						(secondary, $secondary-color) ,
						(success, $success-color),
						(danger, $danger-color),
						(info, $light-font),
						(light, $light-gray),
						(dark, $dark-color),
						(warning, $warning-color) {
			.radio-#{$btn-name}{
				input[type="radio"]
				{
					& + label{
						&::before{
							border-color: $btn-color !important;
						}
						&::after{
							background-color: $btn-color;
						}
					}
					&:checked{
						& + label{
							&::before{
								border-color: $btn-color !important;
							}
							&::after{
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, $primary-color),
						(secondary, $secondary-color) ,
						(success, $success-color),
						(danger, $danger-color),
						(info, $light-font),
						(light, $light-gray),
						(dark, $dark-color),
						(warning, $warning-color) {
			.checkbox-#{$btn-name}{
				label{
					&::before{
						border-color:$btn-color !important;
					}
				}

			}
		}

		@each $btn-name, $btn-color in   (primary, $primary-color),
						(secondary, $secondary-color) ,
						(success, $success-color),
						(danger, $danger-color),
						(info, $light-font),
						(light, $light-gray),
						(dark, $dark-color),
						(warning, $warning-color) {
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}
				input[type="checkbox"] {
					&:checked {
						& + label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}
							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}
		.bootstrap-datetimepicker-widget{
			ul{
				li{
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-card-border;
				}
			}
			tr{
				th,td{
					&:hover{
						background-color: $dark-card-background;
					}
					span{
						&:hover{
							background-color: $dark-card-background;
						}
					}
					&.prev,
					&.next{
						&:hover{
							background-color: $dark-card-background;
						}
					}
				}
				&:first-child{
					th{
						&:hover{
							background-color: $dark-card-background;
						}
					}
				}
			}
		}
		.btn-transparent {
			color: $dark-all-font-color;
		}
		#cal-basic {
			.fc-toolbar {
				.fc-left, .fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
				}
				.fc-left {
					.fc-button-group{
						.fc-month-button{
							color: $white !important;
						}
					}
				}
			}
		}
		.fc-button-group {
			.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
				color: $black !important;
			}
			.fc-month-button {
				color: $white !important;
			}
		}
		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-month-button, .btn-light {
							color: $black !important;
						}
						.fc-agendaWeek-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.basic-calendar, #cal-bg-events, #cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-basicWeek-button , .btn-light {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}

			}
		}
		.categories{
			ul{
				li{
					a {
						color: $dark-small-font-color;
					}					
				}
			}
		}
		.main{
			.langChoice {
				background: $dark-body-background;
				color: $white;
				border: 1px solid $dark-card-border;
			}
		}
		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: $dark-all-font-color;
					}
				}
			}
			span {
				color: $dark-small-font-color;
			}
		}
		.social-status {
			.media {
				.media-body {
					span {
						+ span {
							color: $dark-small-font-color;
						}
					}
					p, .light-span{
						color: $dark-small-font-color;
					}
				}
			}
		}
		.filter-cards-view, .timeline-content {
			p {
				color: $dark-small-font-color;
			}
			.comment-number {
				i {
					color: $dark-small-font-color;
				}
			}
			.comments-box {
				.input-group {
					.form-control{
						border: none !important;
					}
					.input-group-append {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.social-chat {
			.media-body {
				border: 1px solid $dark-card-border;
				&:after{
					border-right: 7px solid $dark-card-background;
				}
			}
			span {
				span {
					color: $dark-small-font-color;
				}
			}
		}
		.details-about {
			.your-details {
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.activity-log {
			.my-activity {
				p {
					color: $dark-small-font-color;
				}
			}
		}
		&.rtl {
			.page-main-header {
				.main-header-right {
					.nav-right {
						> ul {
							> li {
								border-right: none;
								&:nth-child(2) {
									border-right: 1px solid $dark-card-border;
								}
								&:nth-child(5){
									border-right: none;
									border-left: 1px solid $dark-card-border;
								}
								&:nth-child(4) {
									border-left: none;
								}
							}
						}
					}
				}
			}
		}
		.bookmark {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}


	}
	&.dark-sidebar{
		.page-main-header {
			.main-header-right {
				.main-header-left {
					.dark-logo-wrapper{
						display:block;
					}
					.logo-wrapper{
						display:none;
					}
				}
			}
		}

		
		.page-wrapper{
			&.compact-wrapper{
				.page-body-wrapper{				
						header{
							&.main-nav{
								background-color: $dark-card-background;
								border-right: 1px solid $dark-card-border;
								
								
								.nav-menu{
									h6{
										color: $dark-all-font-color;
									}
									> li{
										.nav-link{
											&.active{
												color: $dark-all-font-color;
											}
										}
										.nav-submenu{
											li{											
												&:first-child{
													border-top: 1px solid $dark-card-border;
												}
											}
										}
										&:last-child{
											border-bottom: 1px solid $dark-card-border;
										}										
										> a{
											color: $dark-all-font-color;
										}
									}
									.dropdown {
									ul.nav-submenu {
										li {
											&:hover{
												a{
													color: $white !important;
												}
											}
											a {
												color: rgba($white, 0.8) !important;
												&.active {
													color: $white !important;
													text-shadow: 0px 0px $white;
												}
											}
										}
									}
								}
								}
								.sidebar-user{
									border-color:$dark-card-border;
									ul{
										li{
											span{
												color: $dark-all-font-color;
											}
											+li{
												&:before{
													background-color: $dark-card-border;
												}
											}
										}
									}
									h6{
										color: $dark-all-font-color;
									}
									p{
										color: $dark-all-font-color;
									}								
								}
								.main-navbar{								
									.nav-menu{
										>li{
											.mega-menu-container {
												.mega-box{
													.link-section{
														.submenu-title{
															h5{
																color:$dark-all-font-color;
															}
														}
														.submenu-content{
															&.opensubmegamenu{
																ul{
																	li{
																		a{
																			color: $dark-all-font-color;
																		}
																	}
																}
															}
														}
													}
												}
											}											
										}
									}
								}
								.sidebar-main-title{
									>div{
										border-color:$dark-card-border;
										h6{
											color:$primary-color;
										}
									}
								}
							}
						}
						.according-menu{
							i{
								color: $dark-all-font-color;
							}
						}					
				}
				.page-main-header{
					border-bottom: 1px solid $dark-card-border;
		 			.main-header-left	{
						 background-color: $dark-card-background;
					 }				 
				}				 
			}
		}



		// sidebar 
		.main-navbar {
			ul.nav-pills{
				border-color:$dark-card-border;
				.nav-item{
					&:last-child{
						border-color:$dark-card-border;
					}
					.nav-link{
						color:$dark-all-font-color;
					}
				}
			}
		}	
	}
}


@media(max-width:767px){
	body{
		&.dark-only{
			.chat-box {
				.people-list {
					ul {
						li{
							border-color: $dark-card-border;
						}
					}
				}
			}
		}
	}
	
}
/**=====================
   55. theme layout CSS ends
==========================**/
