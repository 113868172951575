/**=====================
     17. List CSS Start
==========================**/
.list-group-item.active {
	background-color: $primary-color;
	border-color: $primary-color;
}
.list-group{
	i{
		margin-right: $list-group-margin;
	}
	img{
		height: 40px;
		width: 40px;
		margin-right: $list-group-margin;
	}
}
/**=====================
     17. List CSS Ends
==========================**/