/**==========================
   91. Project list css start
=============================**/
.project-list {
	.row {
		margin: 15px;
	}
	button:focus {
		outline: none !important;
	}
	.theme-form .form-group {
		margin-bottom: 15px;
	}

	.border-tab.nav-tabs {
		.nav-item .nav-link {
			border: 1px solid transparent;
			padding: 5px 30px 5px 0;
			border-radius: 5px;
			display: flex;
			align-items: center;
			font-weight: 600;
		}
	}
	.btn {
		// margin-right: 5px;
		vertical-align: -12px;
		float:right;
		svg {
			vertical-align: middle;
			height: 16px;
		}
	}

	ul {
		margin-bottom: 0 !important;
		border-bottom: 0;

		li {
			svg {
				height: 18px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
	}
}

.tab-content {
	.row {
		>div.col-xl-4 {

			$show-numbers: true;

			@if $show-numbers {
				@for $i from 1 through 15 {
					&:nth-child(#{$i}) {
						animation-fill-mode: both;
						animation: fadeIncustom .5s linear #{$i}00000ms;
					}
				}
			}
		}
	}
}

.project-cards {
	.tab-content {
		margin-bottom: -30px;
	}
}
.project-box {
	border: 1px solid $light-gray;
	border-radius: 5px;
	padding: 30px;
	margin-bottom: 30px;
	position:relative;
	h6{
		font-weight: 600;
	}
	p{
	color:$light-text;
	}
	.badge {
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.project-status {
		p {
			margin-bottom: 5px;
			font-weight: 800;
		}
	}
	.media {
		margin-bottom: 15px;
	}
	.details{	
		margin-bottom: 10px;		
	}
}

.projectdetails {
	.card {
		.card-body {
			padding: 20px;
		}

		.card-footer {
			padding: 20px;
		}

		select {
			width: 90px;
			height: 28px;
			font-size: 11px;
			right: 20px;
			top: 20px;
		}
	}
}

.projectmore {
	.details {
		.col-4 {
			margin-bottom: 5px;

			&:nth-child(even) {
				opacity: 0.5;
			}
		}
	}

	h5 {
		font-size: 20px;
		font-weight: 600;
	}

	.task-list {
		position: relative;

		ul {
			li {
				margin-bottom: 5px
			}

			&+ul {
				position: absolute;
				left: 50%;
				top: 0;
			}
		}
	}
}

.new-users {
	&.activity {
		.gradient-round {
			padding: 13px;
			font-size: 20px;
			line-height: 1.4;
			text-align: center;
			top: unset !important;
			&::after {
				display: none;
			}
		}
	}
}

@media(max-width:767px){
	.project-list {
		.btn{
			float:left;
			margin-top:5px;
		}
	}
	.project-box{
		padding:20px;
	}
}

@media(max-width:420px){
	.project-box{
		padding:15px;
		.badge{
			font-size:8px;
			top:10px;
			right:10px;
		}
	}
	.project-list{
		.border-tab{
			&.nav-tabs{
				display:flex;
				.nav-item {
					width:auto !important;			
					.nav-link{
						padding: 5px 15px 5px 0;
						font-size:14px;
					}		
				}
			}
		}	
	}
}

/**==========================
   91. Project list css end
=============================**/
