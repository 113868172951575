/**=====================
     74. internationalization css start
==========================**/
.hdg_main{
  position: relative;
}
.main {
  .langChoice{
    position:absolute;
    right:30px;
    top:20px;
    padding:10px 12px;
    background-color:rgba($primary-color,0.05);   
    border-color:$light-gray;
    color:$primary-color;
  }
}
/**=====================
     74. internationalization CSS end
==========================**/
